import React, { useState, useEffect, createContext, useContext } from "react";
import "../../css/bootstrap.css";
import "../../css/common.css";
import "../../css/modal.css";
import campCss from "../../css/campaign.module.css";
import { Helmet } from "react-helmet";
import canUseDom from "../utils/canUseDom";
import FormCon from "../Form/FormCon";
import FieldArray from "../Form/FieldArray";
import LoadBtn from "../Form/LoadBtn";

const CampaignContext = createContext({});

export const useCampaignContext = () => useContext(CampaignContext);

export const CampaignContextProvider = ({ children }) => {
  const [showModal, setModal] = useState(false);

  useEffect(() => {
    let id = setTimeout(() => {
      setModal(true);
    }, 3000);
    return () => clearTimeout(id);
  }, []);

  return (
    <CampaignContext.Provider value={{ showModal, setModal }}>
      <Helmet>
        <title>Baashyaam Group - Luxury Apartments in Chennai</title>
      </Helmet>
      {children}
    </CampaignContext.Provider>
  );
};

const CampaignModal = ({ modalClass = "" }) => {
  const { showModal, setModal } = useCampaignContext();

  return (
    <div
      className={`${campCss.formModal}  ${
        showModal ? campCss.modalOpen : modalClass
      }`}
      id="form-modal"
    >
      <div className={campCss.staticForm}>
        <a
          href="tel:+919840477777"
          className={`${campCss.apointForm} p-3 mb-2 text-center d-flex align-items-center justify-content-center`}
        >
          <CallIcon />
          <b>Call Us :&nbsp;</b>
          <span>+91 9840477777</span>
        </a>
        <div className={campCss.apointForm}>
          <div className="text-center">
            <h4 className="mb-1">Get In Touch</h4>
            <p className="f-14">Drop your details below</p>
          </div>
          <CampaignForm />
          <button
            id="closeModalBtn"
            className={`btn btn-block btn-link f-14 ${campCss.closeModalBtn} p-0`}
            onClick={() => setModal(false)}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

const contactForm = {
  inputs: {
    name: {
      name: "name",
      type: "text",
      placeholder: "Enter your name"
    },
    email: {
      name: "email",
      type: "email",
      placeholder: "Enter your email"
    },
    phone_number: {
      name: "phone_number",
      type: "tel",
      placeholder: "Enter your phone number"
    }
  },
  allIds: ["name", "email", "phone_number"]
};

const CampaignForm = () => {
  let additional_1 = "";

  if (canUseDom) {
    if (window.location.search) {
      additional_1 = window.location.search;
    }
  }

  return (
    <FormCon
      succFunc={() => {
        if (canUseDom) {
          window.location.replace("/campaign/plutus-thank-you/");
        }
      }}
      form={contactForm}
      config={{
        url: "api/leads/",
        baseURL: "https://lead.billiontagsads.com/",
        method: "POST"
      }}
      formatData={data => {
        return {
          ...data,
          campaign: 12,
          additional_1
        };
      }}
      renderForm={({ fetching }) => (
        <>
          <FieldArray />
          <div className="form-group">
            <LoadBtn
              fetching={fetching}
              className={`btn btn-block ${campCss.consultationFromBtn}`}
            >
              Submit
            </LoadBtn>
          </div>
        </>
      )}
    />

    // <form
    //     method="POST"
    //     id="contact-form"
    //     action="http://baashyaam.billiontagsads.com/"
    // >
    //     <div className="form-group">
    //         <input
    //             placeholder="Enter Your Name"
    //             type="text"
    //             className="form-control formip"
    //             id="nameip"
    //             name="name"
    //             required
    //         />
    //     </div>
    //     <div className="form-group">
    //         <input
    //             placeholder="Enter Your Phone Number"
    //             type="text"
    //             className="form-control formip"
    //             name="phone_number"
    //             required
    //             onKeyPress={event =>
    //                 event.charCode >= 48 && event.charCode <= 57
    //             }
    //         />
    //     </div>
    //     <div className="form-group">
    //         <input
    //             placeholder="Enter Your Email"
    //             type="text"
    //             className="form-control formip"
    //             id="nameip"
    //             name="email"
    //             required
    //         />
    //     </div>
    //     <div className="form-group">
    //         <input
    //             type="hidden"
    //             className="form-control"
    //             name="campaign"
    //             defaultValue={12}
    //             readOnly
    //         />
    //     </div>
    //     <input
    //         type="hidden"
    //         name="additional_1"
    //         value={keys.utm_medium ? keys.utm_medium : ""}
    //         readOnly
    //     />
    //     <div className="mb-3">
    //         <button
    //             id="submitbtn"
    //             className={`btn btn-block ${campCss.consultationFromBtn}`}
    //         >
    //             Submit
    //         </button>
    //     </div>
    // </form>
  );
};

export const urlParams = url => {
  let keys = {};
  let params = decodeURIComponent(url)
    .replace("?", "")
    .replace(/&amp;/g, "&")
    .split("&");
  params.forEach(par => {
    let x = par.split("=");
    keys[x[0]] = x[1];
  });
  return keys;
};

const CallIcon = () => (
  <svg
    className="mr-2"
    width={30}
    height={30}
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512.005 512.005"
    style={{
      enableBackground: "new 0 0 512.005 512.005"
    }}
    xmlSpace="preserve"
  >
    <path
      style={{ fill: "#F4F4F4" }}
      d="M430.985,430.936c-96.606,96.674-253.295,96.717-349.969,0.102s-96.717-253.295-0.102-349.969
    s253.303-96.717,349.969-0.102c0.034,0.034,0.068,0.068,0.102,0.102c96.614,96.205,96.947,252.51,0.742,349.124
    C431.479,430.441,431.232,430.689,430.985,430.936z"
    />
    <path
      style={{ fill: "#FFFFFF" }}
      d="M388.318,430.936c-41.148,41.225-95.565,66.56-153.6,71.509
    C98.569,490.55-2.167,370.537,9.728,234.388C20.181,114.716,115.055,19.843,234.718,9.389
    c136.158,11.802,236.979,131.746,225.178,267.913C454.869,335.337,429.517,389.746,388.318,430.936z"
    />
    <path
      style={{ fill: "#388E3C" }}
      d="M393.694,362.413l-12.971,13.056c-9.643,9.6-21.606,16.538-34.731,20.139
    c-12.348,3.413-25.37,3.593-37.803,0.512c-24.235-6.443-47.283-16.725-68.267-30.464c-19.294-12.254-37.205-26.556-53.419-42.667
    c-14.857-14.857-28.228-31.113-39.936-48.555c-12.8-18.918-22.938-39.509-30.123-61.184c-8.823-27.639-1.485-57.899,19.029-78.421
    l15.019-15.36c7.501-7.561,19.712-7.62,27.273-0.119c0.043,0.043,0.077,0.077,0.119,0.119l47.957,47.957
    c7.561,7.501,7.62,19.712,0.119,27.273c-0.043,0.043-0.077,0.077-0.119,0.119l-28.16,28.16c-4.873,5.06-5.478,12.851-1.451,18.603
    c9.933,13.611,20.924,26.419,32.853,38.315c13.295,13.346,27.742,25.481,43.179,36.267c0.879,0.614,1.826,1.126,2.816,1.536
    c5.444,2.27,11.716,1.024,15.872-3.157l21.504-21.504l5.973-5.973c7.501-7.561,19.712-7.62,27.273-0.119
    c0.043,0.043,0.077,0.077,0.119,0.119l47.957,47.957c7.543,7.518,7.552,19.729,0.034,27.273
    C393.779,362.337,393.737,362.371,393.694,362.413z"
    />
    <path
      style={{ fill: "#4CAF50" }}
      d="M369.715,373.677l-12.971,12.971c-3.319,3.157-6.921,6.016-10.752,8.533
    c-12.348,3.413-25.37,3.593-37.803,0.512c-24.235-6.443-47.283-16.725-68.267-30.464c-19.294-12.254-37.205-26.556-53.419-42.667
    c-14.857-14.857-28.228-31.113-39.936-48.555c-12.8-18.918-22.938-39.509-30.123-61.184c-8.823-27.639-1.485-57.899,19.029-78.421
    l9.131-9.131c3.618,0.785,6.938,2.586,9.557,5.205l47.957,47.957c7.518,7.586,7.518,19.806,0,27.392l-28.245,28.16
    c-5.077,5.06-5.692,13.082-1.451,18.859c9.771,13.594,20.582,26.402,32.341,38.315c13.321,13.346,27.802,25.481,43.264,36.267
    c5.777,4.019,13.602,3.337,18.603-1.621l8.107-8.107l19.371-19.456c4.736-4.676,11.511-6.63,18.005-5.205
    c3.575,0.836,6.852,2.637,9.472,5.205l47.872,47.957C377.105,353.726,377.225,366.014,369.715,373.677z"
    />
    <path
      d="M255.966,512.003C114.586,511.986-0.017,397.357,0,255.969S114.645-0.014,256.034,0.003
    c67.857,0.009,132.932,26.957,180.932,74.923l0,0c100.002,99.942,100.062,262.033,0.119,362.035
    C389.052,485.02,323.9,512.011,255.966,512.003z M255.966,17.069C124.006,17.078,17.041,124.06,17.05,256.02
    C17.067,387.979,124.041,494.945,256,494.936c131.96-0.009,238.933-106.991,238.916-238.95
    c-0.009-63.369-25.182-124.134-69.99-168.943l0,0C380.211,42.089,319.377,16.899,255.966,17.069z"
    />
    <path
      d="M325.939,406.787c-6.673,0.009-13.321-0.794-19.797-2.389c-25.199-6.673-49.169-17.331-70.997-31.573
    c-19.857-12.706-38.255-27.563-54.869-44.288c-15.275-15.206-28.996-31.906-40.96-49.835
    c-13.286-19.558-23.799-40.875-31.232-63.317c-10.103-30.78-1.911-64.631,21.163-87.381l15.189-15.189
    c10.88-10.914,28.553-10.931,39.458-0.051c0.017,0.017,0.034,0.034,0.051,0.051l47.957,47.957
    c10.914,10.88,10.931,28.553,0.051,39.458c-0.017,0.017-0.034,0.034-0.051,0.051l-28.16,28.16c-2.091,2.082-2.347,5.385-0.597,7.765
    c9.643,13.244,20.318,25.711,31.915,37.291c12.954,12.996,27.034,24.815,42.069,35.328c2.406,1.69,5.683,1.399,7.765-0.683
    l27.477-27.477c11.051-10.564,28.459-10.564,39.509,0l47.957,47.957c10.914,10.88,10.931,28.553,0.051,39.458
    c-0.017,0.017-0.034,0.034-0.051,0.051l-13.141,13.312C370.586,397.579,348.749,406.693,325.939,406.787z M163.806,122.115
    c-2.876-0.009-5.641,1.126-7.68,3.157l-15.189,15.189c-18.432,18.133-25.037,45.124-17.067,69.717
    c7.108,20.992,17.101,40.9,29.696,59.136c11.341,17.007,24.346,32.845,38.827,47.275c15.735,15.795,33.169,29.824,51.968,41.813
    c20.25,13.269,42.487,23.202,65.877,29.44c23.219,5.547,47.65-1.442,64.427-18.432l13.056-13.056
    c4.241-4.224,4.258-11.085,0.034-15.326c-0.009-0.009-0.026-0.026-0.034-0.034l-47.957-47.957
    c-4.224-4.241-11.085-4.258-15.326-0.034c-0.009,0.009-0.026,0.026-0.034,0.034L296.926,320.6
    c-7.927,7.876-20.361,8.951-29.525,2.56c-15.863-11.093-30.711-23.578-44.373-37.291c-12.245-12.211-23.518-25.361-33.707-39.339
    c-6.707-9.173-5.692-21.871,2.389-29.867l0,0l28.16-28.16c4.241-4.224,4.258-11.085,0.034-15.326
    c-0.009-0.009-0.026-0.026-0.034-0.034l-47.957-47.957c-2.057-1.997-4.813-3.106-7.68-3.072L163.806,122.115z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

export default CampaignModal;
