import React, { useState } from "react";
import Layout from "../common/Layout";
import BannerSection from "./BannerSection";
import ProjectDetails from "./ProjectDetails";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import callicon from "../../images/call.png";
import {
  FloorPlan,
  GallerySection,
  Gmap,
  SectionCon,
  Specifications,
  Synopsis
} from "./Project";

function OngoingTemplate({ data }) {
  const [tab, setTab] = useState(0);
  const project = data.ongoingJson;
  const sections = project.sections || [];

  const current = sections[tab];

  return (
    <Layout chatCodeId={project.chatCodeId}>
      <Helmet>
        <title>{project.title}</title>
        <meta name="description" content={project.tagline} />
        <meta property="og:type" content="website" />
        {project.banner && project.banner.childImageSharp && (
          <meta
            property="og:image"
            content={project.banner.childImageSharp.fluid.src}
          />
        )}
      </Helmet>
      <BannerSection
        title={project.title}
        banner={project.bg}
        brouchureUrl={project.brouchureUrl}
        video={project.video}
        rera={project.rera}
        tagline={project.tagline}
        bannerLogo={project.bannerLogo}
      />
      {sections.length > 1 ? (
        <div className="residence-nav border-bottom border-top">
          <div className="container d-flex justify-content-between align-items-center">
            <ul className="tab-list flex-wrap">
              {sections.map((item, idx) => (
                <li className="pl-0" key={idx}>
                  <button
                    className={`button-as-link pr-3 ${
                      idx === tab ? " active" : ""
                    }`}
                    onClick={() => setTab(idx)}
                  >
                    {item.title}
                  </button>
                </li>
              ))}
            </ul>
            {project.callUsNumber && (
              <a
                className="d-flex align-items-center"
                href={`tel:${project.callUsNumber}`}
              >
                <img className="mr-2" height="16" src={callicon} alt="" />
                {project.callUsNumber}
              </a>
            )}
          </div>
        </div>
      ) : (
        <ProjectDetails project_updates={current.project_updates} />
      )}
      {current && <Section project={current} projectLogo={project.logo} />}
    </Layout>
  );
}

export const Section = ({ project, projectLogo = null }) => {
  return (
    <SectionCon>
      <Synopsis synopsis={project.synopsis} />
      <Gmap
        gMapUrl={project.gMapUrl}
        projectLogo={projectLogo}
        address={project.address}
      />
      <Specifications specification={project.specification} />
      <FloorPlan floorPlan={project.floor_plan} />
      <GallerySection gallery={project.gallery} />
      <GallerySection
        title="Project Status"
        gallery={project.project_updates}
      />
    </SectionCon>
  );
};

export const query = graphql`
  query MyQuery($slug: String!) {
    ongoingJson(slug: { eq: $slug }) {
      footer_desc
      short_desc
      slug
      rera
      title
      tagline
      callUsNumber
      chatCodeId
      logo {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      brouchureUrl
      video {
        type
        link
      }
      sections {
        title
        synopsis
        specification {
          a
          q
        }
        floor_plan {
          title
          pricing
          rera
          images {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        highlights
        gMapUrl
        address
        gallery {
          title
          images {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        project_updates {
          title
          images {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      banner {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      bg {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  }
`;

export default OngoingTemplate;
