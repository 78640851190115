import React from "react";
import { Link } from "gatsby";
import Logo from "../common/Logo";
import SocialIcons from "../common/SocialIcons";

const CampaignHeader = () => {
  return (
    <header className="header">
      <div className="container-fluid d-flex justify-content-center justify-content-sm-between align-items-center header-nav">
        <Link
          className="d-flex justify-content-between align-items-center"
          to="/"
        >
          <Logo width="240" />
        </Link>
        <div className="d-none d-sm-block">
          <SocialIcons />
        </div>
      </div>
    </header>
  );
};

export default CampaignHeader;
